import React from 'react';
import {
    Datagrid, DateField,
    List,ReferenceInput, SelectInput,
    Show, ShowButton,
    SimpleShowLayout,
    TextField, TextInput,
} from 'react-admin';
import {BackAction} from "./backButton";
const warrantyFilters = [
    <TextInput source="title" label="Search" />,
    <ReferenceInput source="manufacturerId" label="Company" reference="admin/company" allowEmpty perPage={100}>
        <SelectInput optionText="name" />
    </ReferenceInput>,
];
export const WarrantyRequestList = (props: any) => {
  return (
      <List {...props} sort={{ field: 'createdAt', order: 'DESC' }} filters={warrantyFilters} filter={{status:"Pending"}} >
        <Datagrid>
          {/*<Link prefix="#/admin/user/" postfix="/show" source="product.owner._id" name="product.owner.info.fullName" label="Product owner" />*/}
          {/*<Link prefix="#/admin/product/" postfix="/show"  source="productId" name="product.name" label="Product" />*/}
          {/*<Link prefix="#/admin/warranty/" postfix="/show"  source="product.warrantyId" name="product.warrantyId" label="Warranty" />*/}
          {/*<CustomLink source="product.owner._id" label="Transaction in explorer" showLabel="Show"/>*/}
          {/*<TextField source="product.description" label="Warranty" />*/}
            <TextField source="title" label="Name" />
            <TextField source="period" label="Period" />
            <TextField source="status" label="Status" />
          <ShowButton />
        </Datagrid>
      </List>
  );
};
export const  WarrantyRequestShow = (props: any) => {

    return (
        <Show {...props} actions={<BackAction basePath="admin/warranty-requests?filter[status]=Pending" />}  >

            <SimpleShowLayout>
                {/*<Link prefix="#/admin/user/" postfix="/show" source="product.owner._id" name="product.owner.info.fullName" label="Product owner" />*/}
                {/*<Link prefix="#/admin/product/" postfix="/show"  source="productId" name="product.name" label="Product" />*/}
                {/*<Link prefix="#/admin/warranty/" postfix="/show"  source="product.warrantyId" name="product.warrantyId" label="Warranty" />*/}
                {/*<CustomLink source="product.owner._id" label="Transaction in explorer" showLabel="Show"/>*/}
                {/*<TextField source="product.description" label="Warranty" />*/}
                <TextField source="title" label="Name" />
                <TextField source="description" label="Description" />
                <TextField source="status" label="Status" />
                <TextField source="period" label="Period" />
                <DateField source="startDate" label="Start Date" />
                <DateField source="endDate" label="End Date" />
            </SimpleShowLayout>
        </Show>
    );
}

