import React, {useEffect} from 'react';
import {
    Create,
    Datagrid,
    DateField,
    Edit,
    ImageField,
    ImageInput,
    List,
    ReferenceInput,
    required,
    SelectInput,
    Show,
    ShowButton,
    SimpleForm,
    Tab,
    TabbedShowLayout,
    TextField,
    TextInput,
    FunctionField,
} from 'react-admin';
import QRCode from 'react-qr-code';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Paper from '@mui/material/Paper';
import { ImageLink } from './imageLink';
import {BackAction} from "./backButton";
import {CustomToolbar} from "./customToolbar";

const validateProduct = [required()];
const warrantyOptionRenderer = (choice: any) => {
    return (choice.info.hasOwnProperty('title')) ? choice.info.title : choice.info.address;
}

const productFilters = [
  <TextInput source="name" label="Search" />,
  <ReferenceInput source="manufacturerId" label="Company" reference="admin/company" allowEmpty perPage={100}>
    <SelectInput optionText="name" />
  </ReferenceInput>,
    <ReferenceInput source="ownerId" label="Owner" reference="admin/user" allowEmpty perPage={100}>
        <SelectInput optionText="info.fullName" />
    </ReferenceInput>,
];

const copyToClipBoard = (text: string) => {
  navigator.clipboard.writeText(text);
};

// TODO ::: will remove this comment in future

// @ts-ignore
const Image = ({ value }: any) => <QRCode style={{ marginTop: 20, marginBottom: 20 }} value={value} size={179} />;

const LinkContent = ({ link }: any) => (
  <Paper
    component="form"
    sx={{ p: '0 10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 340, border: '1px solid #e2e8f0', height: 52, backgroundColor: '#f7fafc', boxShadow: 'none' }}
  >
    <div style={{ color: '#718096', fontSize: 16, maxWidth: 300, overflow: 'hidden', textOverflow: 'ellipsis' }}>{link}</div>
    <IconButton sx={{ p: '10px' }} aria-label="menu" onClick={() => copyToClipBoard(link)}>
      <ContentCopyIcon />
    </IconButton>
  </Paper>
);

const QrCodeImageContent = () => (
  <div style={{display: 'flex', paddingBottom: 20 }}>
    <div style={{ marginRight: 30 }}>
      <FunctionField label="Public Qr Code" render={(record: any) => {
        return record?.publicQrCode?.Location && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ color: '#1a202c', fontSize: 16 }}>Public Qr Code</div>
            <Image value={`https://app.veritise.com/p/${record?.alias}`}  />
            <div style={{ color: '#718096', fontSize: 12, marginBottom: 5 }}>Link</div>
            <LinkContent link={`https://app.veritise.com/p/${record?.alias}`} />
          </div>
        )
      }} />
    </div>
    <div style={{ marginLeft: 30 }}>
      <FunctionField label="Public Qr Code" render={(record: any) => {
        return record?.privateQrCode?.Location && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ color: '#1a202c', fontSize: 16 }}>Private Qr Code</div>
            <Image value={process.env.REACT_APP_ATTACHMENTS_DOMAIN + record?.privateQrCode?.Location}  />
            <div style={{ color: '#718096', fontSize: 12, marginBottom: 5 }}>Link</div>
            <LinkContent link={record?.privateQrCode?.link} />
          </div>
        )
      }} />
    </div>
  </div>
);

export const ProductList = (props: any) => {
    const elements = document.querySelectorAll('.description')
    useEffect(() => {
        if(elements){
            elements.forEach((item) => {
                item.textContent =  item.textContent?.slice(0,30) + `${`${item.textContent}`.length > 30? '...':''}` || ''
            })
        }
    },[elements])
  return (
    <List {...props} bulkActionButtons={false} filters={productFilters} sort={{ field: 'createdAt', order: 'DESC' }}>
      <Datagrid>
        <TextField source="name" label="Name" sortable />
        <TextField source="status" sortable={true} />
        <DateField source="createdAt" sortable={true} />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const ProductShow = (props: any) => (
  <Show {...props} actions={<BackAction/>}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <TextField source="name" label="Name" />
        <TextField source="description" label="Description" />
        <ImageLink source="info.images" label="Images" />
        <TextField source="status" label="Status" />
        <TextField source="manufacturer.organization.name" label="Manufacturer" />
        <TextField source="warranty.status" label="Warranty Status" />
        <TextField source="owner.info.name" label="Owner" defaultValue="manufacturer.info.fullName" emptyText="-" />
        <TextField source="createdAt"  />
        <TextField source="updatedAt"  />
      </Tab>
      <Tab label="Qr" path="body">
        <QrCodeImageContent />
      </Tab>
        <Tab label="Warranty" path="warranty" >
          <TextField source="warranty.status" label="Status"  />
          <TextField source="warranty.title" label="Title"  />
          <TextField source="warranty.description" label="Description"  />
          <DateField source="warranty.endDate" label="End Date"  />
          <TextField  label="Info"  />
          <TextField source="warranty.info.address" label="Address"  />
          <TextField source="warranty.info.email" label="Email"  />
          <TextField source="warranty.info.phone" label="Phone"  />
        </Tab>
    </TabbedShowLayout>
  </Show>
);

export const ProductEdit = (props: any) => (
  <Edit {...props} actions={<BackAction/>}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <ReferenceInput
        perPage={100}
        source="warrantyId"
        reference="admin/warranty"
        label="Warranty"
        sort={{ field: 'createdAt', order: 'DESC' }}
        validate={validateProduct}>
        <SelectInput optionText={warrantyOptionRenderer} />
      </ReferenceInput>
      <ReferenceInput
        perPage={100}
        source="producerId"
        reference="admin/company"
        label="Company"
        sort={{ field: 'createdAt', order: 'DESC' }}
        validate={validateProduct}>
        <SelectInput optionText="info.fullName" />
      </ReferenceInput>
      <TextInput source="name" validate={validateProduct} />
      <TextInput source="description" validate={validateProduct} multiline />
    </SimpleForm>
  </Edit>
);

export const ProductCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        perPage={100}
        source="warrantyId"
        reference="admin/warranty"
        label="Warranty"
        sort={{ field: 'createdAt', order: 'DESC' }}
        validate={validateProduct}>
          <SelectInput optionText={warrantyOptionRenderer} />
      </ReferenceInput>
      <ReferenceInput
        perPage={100}
        source="manufacturerId"
        reference="admin/company"
        label="Company"
        sort={{ field: 'createdAt', order: 'DESC' }}
        validate={validateProduct}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ImageInput source="info.images" label="Related pictures" accept="image/jpeg, image/png" multiple>
        <ImageField source="src" />
      </ImageInput>
      <TextInput source="name" validate={validateProduct} />
      <TextInput source="description" validate={validateProduct} multiline />
    </SimpleForm>
  </Create>
);
