import { useState } from 'react';
import {
  regex,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  ImageField,
  List,
  required,
  Show,
  ShowButton,
  SimpleForm,
  TextField,
  TextInput,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  FunctionField,
} from 'react-admin';
import { BackAction } from './backButton';
import { CustomToolbar } from './customToolbar';
import { useSelector } from 'react-redux';
import { AmplifyFileInput } from 'react-admin-amplify';
import { Accordion, AccordionDetails, AccordionSummary, Stack } from '@mui/material';
import { ExpandMore } from '@material-ui/icons';
import { API } from 'aws-amplify';
import { API_KEY } from '../config/constants';
import { LoadingButton } from '@mui/lab';

const validateAccountRepresentative = [required()];
const validatePhoneNumber = regex(
  /^\+\d{10}$/,
  'Phone number should start from + and has 10 digits',
);
const validatePasswordNumber = [
  required(),
  regex(
    /^(?=.*?[A-Z])+(?=.*?[a-z])(?=.*?[0-9]).{6,}$/,
    'Password should contains: at least one upper case, at lease one lowercase, at least one digit and minimum eight in length 6',
  ),
];

const companyFilters = [<TextInput source="search" label="Search" />];
export const CompanyList = (props: any) => {
  return (
    <List {...props} filters={companyFilters} sort={{ field: 'createdAt', order: 'DESC' }}>
      <Datagrid>
        {/*<TextField source="email" label="Email" sortable />*/}
        {/*<TextField source="info.fullName" label="Name" sortable emptyText="-" />*/}
        <FunctionField
          label="Logo"
          render={(record: any) => {
            if (record.info?.images?.[0]?.location) {
              return <ImageField source="info.images[0].location" label="Logo" className="img" />;
            } else {
              console.log(2);
              return (
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/768px-No_image_available.svg.png"
                  title="Logo"
                  width={100}
                  alt="Logo"
                />
              );
            }
          }}
        />

        <TextField source="name" label="Company name" sortable emptyText="pending" />
        <TextField source="jurisdictionCode" label="Country registration" sortable />
        <TextField source="registrationNumber" label="Registration number" sortable />
        <TextField source="kyb_status" label="KYB Status" sortable emptyText="pending" />
        <DateField source="createdAt" sortable={false} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const apiName = 'MyAPIGatewayAPI';

export const CompanyShow = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const { data } = useSelector((state: any) => state.admin.resources['admin/company']);
  const accountId = window.location.hash.replace('/show', '').split('/').pop();
  const approvalStatus = data[accountId || 'placeholder']?.approvalStatus || '';
  const organizationsFound =
    data[accountId || 'placeholder']?.kybData?.verification_data?.kyb || [];

  const handleApprove = async () => {
    setIsLoading(true);
    await API.post(apiName, `/admin/company/${accountId}/approve`, {
      headers: {
        'X-Api-Key': API_KEY,
      },
      response: true,
      body: {},
    });
    window.location.reload();
  };

  const handleDecline = async () => {
    setIsLoading(true);
    await API.post(apiName, `/admin/company/${accountId}/decline`, {
      headers: {
        'X-Api-Key': API_KEY,
      },
      response: true,
      body: {},
    });
    window.location.reload();
  };

  return (
    <Show {...props} actions={<BackAction />}>
      <TabbedShowLayout syncWithLocation={false}>
        <Tab label="summary">
          <FunctionField
            label="Logo"
            render={(record: any) => {
              if (record.info?.images?.[0]?.location) {
                return <ImageField source="info.images[0].location" label="Logo" className="img" />;
              } else {
                return (
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/768px-No_image_available.svg.png"
                    title="Logo"
                    width={100}
                    alt="Logo"
                  />
                );
              }
            }}
          />
          <TextField source="name" label="Company name" sortable emptyText="pending" />
          <TextField source="jurisdictionCode" label="Country registration" sortable />
          <TextField source="registrationNumber" label="Registration number" sortable />
          <TextField source="kybStatus" label="Shufti Status" sortable emptyText="pending" />
          <TextField source="approvalStatus" label="Approval Status" sortable emptyText="pending" />
          <DateField source="createdAt" sortable={false} />
          {/*<TextField source="type" label="Type" />*/}
          {/*<TextField source="walletAddress" label="Wallet address" />*/}
          {/*<TextField source="publicKey" label="Wallet public key" />*/}
          {/*<TextField source="kyb_status" label="KYB" sortable emptyText="pending" />*/}
          {/*<TextField source="kyb_reason" label="KYB Declined reason" emptyText="-" />*/}
          {/*<TextField source="notificationToken" label="Notification Token" emptyText="-" />*/}
          {/*<DateField source="createdAt" />*/}
        </Tab>
        <Tab label="shufti data">
          <Stack direction="row" gap={1} sx={{ mb: 2 }}>
            {approvalStatus !== 'accepted' && (
              <LoadingButton
                variant="contained"
                color="success"
                onClick={handleApprove}
                loading={isLoading}>
                Accept
              </LoadingButton>
            )}
            {approvalStatus !== 'declined' && (
              <LoadingButton
                variant="contained"
                color="error"
                onClick={handleDecline}
                loading={isLoading}>
                Decline
              </LoadingButton>
            )}
          </Stack>

          {organizationsFound.map((organization: any, index: number) => (
            <Accordion sx={{ mb: '16px !important' }} key={index}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header">
                {organization.company_name}
              </AccordionSummary>
              <AccordionDetails>
                <table>
                  {Object.keys(organization).map((key: string, index: number) => {
                    return (
                      <tr key={index}>
                        <td style={{ paddingRight: '8px' }}>{key}</td>
                        <td>
                          <pre>{JSON.stringify(organization[key], null, 2)}</pre>
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </AccordionDetails>
            </Accordion>
          ))}
        </Tab>
        {/*accountId*/}
        <Tab label="Products" path="products">
          <ReferenceManyField
            reference="admin/product"
            target="none"
            sort={{ field: 'createdAt', order: 'DESC' }}
            filter={{ manufacturerId: accountId }}
            addLabel={false}>
            <Datagrid>
              <TextField source="name" label="Name" sortable />
              <TextField source="description" label="Description" sortable={false} />
              <DateField source="createdAt" sortable={true} />
              <DateField source="updatedAt" sortable={true} />
              {/*<ShowButton/>*/}
              {/*<EditButton/>*/}
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Purchased" path="purchased/products">
          <ReferenceManyField
            reference="admin/product"
            target="none"
            sort={{ field: 'createdAt', order: 'DESC' }} //filter={{status:'authentic'}}
            filter={{ manufacturerId: accountId, status: 'authentic' }}
            // filter={{status:'registered'}}
            addLabel={false}>
            <Datagrid>
              <TextField source="name" label="Name" sortable />
              <TextField
                source="description"
                label="Description"
                sortable={false}
                className="description"
              />
              {/*<ImageLink source="publicQrCode.Location" label="Public QrCode" />*/}
              <TextField source="status" sortable={true} />
              <DateField source="createdAt" sortable={true} />
              {/*<EditButton/>*/}
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Warranties" path="warranties/products">
          <ReferenceManyField
            reference="admin/warranty"
            target="none"
            sort={{ field: 'createdAt', order: 'DESC' }}
            addLabel={false}
            filter={{ manufacturerId: accountId }}>
            <Datagrid>
              <TextField source="title" label="Name" />
              <TextField source="period" label="Period" />
              <TextField source="status" label="Status" />
              <DateField source="createdAt" />
              {/*<EditButton/>*/}
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Employers" path="employers">
          <ReferenceManyField
            reference="admin/company"
            target="ownerId"
            sort={{ field: 'createdAt', order: 'DESC' }}
            addLabel={false}
            filter={{ customURL: `admin/company/:id/employers` }}>
            <Datagrid>
              <TextField source="email" label="Email" />
              <TextField source="role" label="Role" />
              <TextField source="status" label="Status" />
              <DateField source="createdAt" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const CompanyEdit = (props: any) => (
  <Edit {...props} actions={<BackAction />}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput source="email" label="Email" validate={validateAccountRepresentative} />
      <TextInput source="info.fullName" label="Name" validate={validateAccountRepresentative} />
      <TextInput source="info.address" label="Address" />
      <TextInput source="info.logo" label="Logo" />
      <TextInput source="info.phone" label="Phone" validate={validatePhoneNumber} />
    </SimpleForm>
  </Edit>
);

export const CompanyCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="email" label="Email" validate={validateAccountRepresentative} />
      <TextInput source="password" label="Password" validate={validatePasswordNumber} />
      <TextInput source="info.fullName" label="Name" validate={validateAccountRepresentative} />
      <AmplifyFileInput source="info.images" label="Logo" />
      <TextInput source="info.address" label="Address" />
      <TextInput source="info.phone" label="Phone" validate={validatePhoneNumber} />
    </SimpleForm>
  </Create>
);
