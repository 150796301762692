import React from 'react';
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  List, regex,
  required,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  NumberInput,
  TextInput,
  FunctionField,
  SelectInput,
} from 'react-admin';
import {CustomToolbar} from "./customToolbar";
import {BackAction} from "./backButton";

const validateAccountRepresentative = [required()];
const validatePhoneNumber = regex(/^\+\d{10,13}$/, 'Phone number should start from + and has 13 digits');

const warantyFilters = [
  <TextInput source="title" label="Name" />,
  <NumberInput source="period" label="Period" />,
  <SelectInput source="status" label="Status" choices={[
      { id: 'Not active', name: 'Not active' },
      { id: 'Pending', name: 'Pending' },
      { id: 'Active', name: 'Active' },
      { id: 'Declined', name: 'Declined' },
      { id: 'Expired', name: 'Expired' },
    ]}
  />
];

export const WarrantyList = (props: any) => {
  return (
    <List {...props} filters={warantyFilters} sort={{ field: 'createdAt', order: 'DESC' }}>
      <Datagrid>
        {/*<TextField source="info.title" label="Title" />*/}
        {/*<TextField source="info.address" label="Address" />*/}
        <TextField source="title" label="Name" />
        <TextField source="period" label="Period" />
        <TextField source="status" label="Status" />
        {/*<DateField source="createdAt" />*/}
        {/*<DateField source="updatedAt" />*/}
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const WarrantyShow = (props: any) => (
  <Show {...props} actions={<BackAction/>}>
    <SimpleShowLayout>
      <TextField source="title" label="Name" />
      <TextField source="description" label="Description" aria-multiline />
      <DateField source="startDate" emptyText="-" />
      <DateField source="endDate" emptyText="-" />
      <TextField source="info.address" label="Address" />
      <TextField source="info.email" label="Email" />
      <TextField source="info.phone" label="Phone" />
      <FunctionField label="period" render={(record: any) => `${record.period} days`} />
      <TextField source="status" label="Status" />
      <DateField source="createdAt" />
    </SimpleShowLayout>
  </Show>
);

export const WarrantyEdit = (props: any) => {
  console.log(props)
  return(
      <Edit {...props} toolbar={<CustomToolbar />}>

        <SimpleForm>
          <TextInput
              source="title"
              label="Name"
              validate={validateAccountRepresentative}
          />
          <TextInput
              source="description"
              label="Description"
          />

          <SelectInput source="status" label="Status" choices={[
            { id: 'Not active', name: 'Not active' },
            { id: 'Pending', name: 'Pending' },
            { id: 'Active', name: 'Active' },
            { id: 'Declined', name: 'Declined' },
            { id: 'Expired', name: 'Expired' },
          ]}
          />
          <TextInput
              source="info.email"
              label="Email"
              type="email"
          />
          <TextInput source="info.phone" label="Phone" validate={validatePhoneNumber}  />
          <TextInput source="info.address" label="Address" />
          <DateField source="startDate" emptyText="-" />
          <DateField source="endDate" emptyText="-" />
        </SimpleForm>
      </Edit>
  );
}

export const WarrantyCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
          source="title"
          label="Name"
          validate={validateAccountRepresentative}
      />
      <TextInput source="description" label="Description" multiline />
      <NumberInput source="period" label="Period" validate={validateAccountRepresentative} />
      <TextInput
        source="manufacturerId"
        label="Manufacturer Id"
        validate={validateAccountRepresentative}
      />
      <TextInput
        source="info.email"
        label="Email"
        type="email"
      />
      <TextInput source="info.phone" label="Phone" validate={validatePhoneNumber}  />
      <TextInput source="info.address" label="Address" />
    </SimpleForm>
  </Create>
);
