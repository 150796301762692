import React, { useState, useEffect } from 'react';
import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  required,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  ArrayField,
} from 'react-admin';
import {useSelector} from 'react-redux';
import { API } from '@aws-amplify/api';
import {CustomToolbar} from "./customToolbar";
import {BackAction} from "./backButton";
import {API_KEY} from "../config/constants";

import {
  Card,
  TextField as MuiTextField,
  CardContent,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

const validateAccountRepresentative = [required()];
const apiName = 'MyAPIGatewayAPI';
const mainHeaders = {
    'X-Api-Key': API_KEY,
};

export const ChangelogList = (props: any) => {
  return (
    <List {...props} sort={{ field: null, order: null }}>
      <Datagrid>
        <TextField source="version" label="Version" />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const ChangelogShow = (props: any) => (
  <Show {...props} actions={<BackAction/>} className="changelog-show">
    <SimpleShowLayout>
      <TextField source="version" label="Version" />
      <ArrayField source="items">
        <Datagrid>
          <TextField source="type" label="Type" />
          <TextField source="description" label="Description" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

export const ChangelogEdit = (props: any) => {
  const {data} = useSelector((state: any) => state.admin.resources['admin/changelog']);
  const [changelogItems, setChangelogItems] = useState<any>([]);

  useEffect(() => {
    setChangelogItems(data?.[props?.id]?.items || []);
  }, [data, props?.id]);

  const fetchData = () => {
    const myInit = {
      headers: { ...mainHeaders },
      response: true,
    };
    return API.get(apiName, `/admin/changelog/${props?.id}`, myInit).then(({data}) => {
      console.log('data get :::', data);
      setChangelogItems(data?.items);
    })
  };

  const [addedItem, setAddedItem] = useState<any>();
  const [removedItem, setRemovedItem] = useState<any>();
  const [changedItem, setChangedItem] = useState<any>();
  const [isBusy, setIsBusy] = useState<boolean>(false);

  useEffect(() => {
    const added = changelogItems?.find((item: any) => item.type === 'Added');
    const removed = changelogItems?.find((item: any) => item.type === 'Removed');
    const changed = changelogItems?.find((item: any) => item.type === 'Changed');
    setAddedItem(added);
    setRemovedItem(removed);
    setChangedItem(changed);
  }, [changelogItems]);

  const handleChange = (value: any, func: any) => {
    func((prev: any) => ({ ...prev, description: value }))
  };

  const onSubmit = (id: any, item: any, type: string) => {
    const myInit = {
      headers: { ...mainHeaders },
      response: true,
      body: { description: item.description, type },
    };
    setIsBusy(true);

    if (id) {
      return API.put(apiName, `/admin/changelog/${props?.id}/item/${id}`, myInit).then(({data}) => {
        setIsBusy(false);
        fetchData();
        console.log('data put :::', data);
      }).catch(() => {
        setIsBusy(false);
      });
    } else {
      return API.post(apiName, `/admin/changelog/${props?.id}/item`, myInit).then(({data}) => {
        setIsBusy(false);
        fetchData();
        console.log('data post :::', data);
      }).catch(() => {
        setIsBusy(false);
      });;
    }
  };

  const onDelete = (id: any, item: any, type: string) => {
    const myInit = {
      headers: { ...mainHeaders },
    };
    setIsBusy(true);
    return API.del(apiName, `/admin/changelog/${props?.id}/item/${id}`, myInit).then(({data}) => {
      setIsBusy(false);
      fetchData();
      console.log('data delete :::', data);
    }).catch(() => {
      setIsBusy(false);
    });
  }

  return (
    <div>
      <Edit {...props} toolbar={<CustomToolbar />}>
        <SimpleForm>
          <TextInput
              source="version"
              label="Version"
              validate={validateAccountRepresentative}
          />
        </SimpleForm>
      </Edit>
      <Card style={{ marginTop: 20 }}>
        <CardContent>
          <Typography style={{ marginBottom: 20 }}>Changelog Items</Typography>
          <MuiTextField label="Type" style={{ marginRight: 20 }} value="Added"  disabled/>
          <MuiTextField 
            label="Description" 
            style={{ marginTop: 20, marginBottom: 20 }} 
            value={addedItem?.description || ''} 
            onChange={(e) => handleChange(e.target.value, setAddedItem)} 
            multiline
            maxRows={5}
            fullWidth
          />
          <LoadingButton 
            loading={isBusy}
            variant="contained"
            startIcon={<SaveIcon />} 
            style={{ marginRight: 10 }}
            onClick={() => onSubmit(addedItem._id, addedItem, 'Added')}
          >Save</LoadingButton>
          <LoadingButton 
            loading={isBusy}
            variant="outlined"
            color="error"
            startIcon={<SaveIcon />} 
            disabled={!addedItem?._id}
            onClick={() => onDelete(addedItem?._id, removedItem, 'Removed')}
          >Delete</LoadingButton>
        </CardContent>
        <CardContent>
          <MuiTextField label="Type" style={{ marginRight: 20 }} value="Removed" disabled />
          <MuiTextField 
            label="Description" 
            style={{ marginTop: 20, marginBottom: 20 }} 
            value={removedItem?.description || ''} 
            onChange={(e) => handleChange(e.target.value, setRemovedItem)} 
            multiline
            maxRows={5}
            fullWidth
          />
          <LoadingButton 
            loading={isBusy}
            variant="contained"
            startIcon={<SaveIcon />} 
            style={{ marginRight: 10 }}
            onClick={() => onSubmit(removedItem?._id, removedItem, 'Removed')}
          >Save</LoadingButton>
          <LoadingButton 
            loading={isBusy}
            variant="outlined"
            color="error"
            startIcon={<SaveIcon />} 
            disabled={!removedItem?._id}
            onClick={() => onDelete(removedItem?._id, removedItem, 'Removed')}
          >Delete</LoadingButton>
        </CardContent>
        <CardContent>
          <MuiTextField label="Type" style={{ marginRight: 20 }} value="Changed" disabled />
          <MuiTextField 
            label="Description" 
            style={{ marginTop: 20, marginBottom: 20 }} 
            value={changedItem?.description || ''} 
            onChange={(e) => handleChange(e.target.value, setChangedItem)} 
            multiline
            maxRows={5}
            fullWidth
          />
          <LoadingButton 
            loading={isBusy}
            variant="contained"
            startIcon={<SaveIcon />} 
            style={{ marginRight: 10 }}
            onClick={() => onSubmit(changedItem?._id, changedItem, 'Changed')}
          >Save</LoadingButton>
          <LoadingButton 
            loading={isBusy}
            variant="outlined"
            color="error"
            disabled={!changedItem?._id}
            startIcon={<SaveIcon />} 
            onClick={() => onDelete(changedItem?._id, removedItem, 'Removed')}
          >Delete</LoadingButton>
        </CardContent>
      </Card>
    </div>
  );
}

export const ChangelogCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
          source="version"
          label="Version"
          validate={validateAccountRepresentative}
      />

      {/* <TextInput
          source="description"
          label="Description"
          validate={validateAccountRepresentative}
      /> */}
    </SimpleForm>
  </Create>
);
