import { DataProvider, fetchUtils } from 'ra-core';
import { API } from '@aws-amplify/api';
import { API_KEY } from './config/constants';

const apiName = 'MyAPIGatewayAPI';
const mainHeaders = {
  'X-Api-Key': API_KEY,
};

const generateCleanData: any = (data: { [key: string]: any }): any => {
  const newData = { ...data };

  for (const key in newData) {
    if (!newData[key]) {
      delete newData[key];
    }
  }

  return newData;
};

const dataProvider = (
  apiUrl: string = '',
  httpClient = fetchUtils.fetchJson,
  countHeader: string = 'Content-Range',
): DataProvider => ({
  getList: (resource, params) => {
    console.log('getList :::', { params, resource });
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const rangeStart = (page - 1) * perPage;
    const rangeEnd = page * perPage;
    console.log(params);
    const query =
      field && order
        ? {
            [`order[${field === 'id' ? '_id' : field}]`]: order.toLowerCase(),
            range: JSON.stringify([rangeStart, rangeEnd - rangeStart]),
            // filter: Object.keys(params.filter).length  ?JSON.stringify(params.filter) : undefined,
          }
        : null;
    if (Object.keys(params.filter).length && query) {
      Object.keys(params.filter).forEach(item => {
        if (item === 'title' || item === 'name' || item === 'search') {
          params.filter[item]?.length > 2 &&
            Object.assign(query, { [`filter[${item}]`]: params.filter[item] });
        } else {
          Object.assign(query, { [`filter[${item}]`]: params.filter[item] });
        }
      });
    }

    if (query) {
      // @ts-ignore
      Object.keys(query).forEach((key: keyof typeof query) => {
        if (query[key] === undefined) {
          delete query[key];
        }
      });
    }
    // ?${stringify(query)}
    const url = `${apiUrl}/${resource}`.replace('warranty-requests', 'warranty');
    const options =
      countHeader === 'Content-Range'
        ? {
            // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
            'content-range': `${resource}=${rangeStart}-${rangeEnd}`,
          }
        : {};
    const myInit = {
      headers: {
        ...mainHeaders,
        ...options,
      },
      response: true,
      ...(query && { queryStringParameters: query }),
    };
    console.log(3);
    return API.get(apiName, url, myInit).then(
      ({ data: { data, total }, headers, config, ...res }) => {
        return {
          data: data.map((item: any) => ({ ...item, id: item._id })),
          total,
        };
      },
    );
  },

  getOne: (resource, params) => {
    console.log('getOne :::', { params, resource });
    const myInit = {
      // OPTIONAL
      headers: {
        ...mainHeaders,
      },
      response: true,
    };

    return API.get(
      apiName,
      `${apiUrl}${
        resource !== 'admin/warranty?filter[status]=Pending'
          ? `/${resource}/` + params.id
          : `/admin/warranty/${params.id}`
      }`.replace('warranty-requests', 'warranty'),
      myInit,
    ).then(({ data }) => {
      return {
        data: { ...data, id: data._id },
      };
    });
  },

  getMany: (resource, params) => {
    console.log('getMany :::', { params, resource });
    const url = `${apiUrl}/${resource}/${params.ids}`.replace('warranty-requests', 'warranty');
    const myInit = {
      headers: {
        ...mainHeaders,
      },
      response: true,
    };
    console.log(1);
    return API.get(apiName, url, myInit).then(({ data: { data } }) => {
      return {
        data: data.map((item: any) => ({ ...item, id: item._id })),
      };
    });
  },

  getManyReference: (resource, params) => {
    console.log('getManyReference :::', { params, resource });
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    params.filter = { ...params.filter, [params.target]: params.id };
    const rangeStart = (page - 1) * perPage;
    const rangeEnd = page * perPage - 1;

    const query = {
      [`order[${field === 'id' ? '_id' : field}]`]: order.toLowerCase(),
      range: JSON.stringify([rangeStart, rangeEnd - rangeStart]),
    };
    let uri: null | string = null;
    if (params.filter.customURL) {
      uri = params.filter.customURL.replace(':id', params.id);
      delete params.filter.customURL;
    }
    console.log(uri);
    if (Object.keys(params.filter).length && !uri) {
      Object.keys(params.filter).forEach(item => {
        if (item !== 'none') Object.assign(query, { [`filter[${item}]`]: params.filter[item] });
      });
    }

    // @ts-ignore
    Object.keys(query).forEach((key: keyof typeof query) => {
      if (query[key] === undefined) {
        delete query[key];
      }
    });

    const url = uri
      ? `${apiUrl}/${uri}`
      : `${apiUrl}/${resource}?${new URLSearchParams(query).toString()}`.replace(
          'warranty-requests',
          'warranty',
        );
    const options =
      countHeader === 'Content-Range'
        ? {
            // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
            headers: new Headers({
              Range: `${resource}=${rangeStart}-${rangeEnd}`,
            }),
          }
        : {};

    const myInit = {
      headers: {
        ...mainHeaders,
        ...options,
      },
      response: true,
      queryStringParameters: query,
    };
    console.log(4);
    return API.get(apiName, url, myInit).then(
      ({ data: { data, total }, headers, config, ...res }) => {
        return {
          data: data.map((item: any) => ({ ...item, id: item._id })),
          total,
        };
      },
    );
  },

  update: (resource, params) => {
    console.log(resource, params);
    if (resource.match(/admin\/warranty/gi)) {
      delete params.data.endDate;
      delete params.data.startDate;
      delete params.data.ownerId;
      params.data.id = params.id;
    }
    const myInit = {
      headers: {
        ...mainHeaders,
      },
      response: true,
      body: generateCleanData(convertData(params, true)),
    };
    return API.put(
      apiName,
      `${apiUrl}/${resource}/${params.id}`.replace('warranty-requests', 'warranty'),
      myInit,
    );
  },
  // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map(id =>
        httpClient(`${apiUrl}/${resource}/${id}`.replace('warranty-requests', 'warranty'), {
          method: 'PUT',
          body: JSON.stringify(params.data),
        }),
      ),
    ).then(responses => ({ data: responses.map(({ json }) => json.id) })),

  create: async (resource, params) => {
    let data = await convertData(params);

    const myInit = {
      headers: {
        ...mainHeaders,
      },
      response: true,
      body: data,
    };

    return API.post(
      apiName,
      `${apiUrl}/${resource}`.replace('warranty-requests', 'warranty'),
      myInit,
    ).then(({ data }) => ({
      data: { ...data, id: data._id },
    }));
  },

  delete: (resource, params) => {
    const myInit = {
      headers: {
        ...mainHeaders,
      },
      response: true,
    };
    return API.del(
      apiName,
      `${apiUrl}/${resource}/${params.id}`.replace('warranty-requests', 'warranty'),
      myInit,
    ).then(({ data }) => ({
      data: data ? { ...data, id: data?._id } : {},
    }));
  },

  deleteMany: (resource, params) => {
    const myInit = {
      headers: {
        ...mainHeaders,
      },
      response: true,
    };

    return Promise.all(
      params.ids.map(id =>
        API.del(
          apiName,
          `${apiUrl}/${resource}/${id}`.replace('warranty-requests', 'warranty'),
          myInit,
        ),
      ),
    ).then(responses => ({
      data: responses.map(({ data }) => data?.id),
    }));
  },
});

const convertData = (params: any, isUpdate?: boolean) => {
  delete params.data['_id'];
  delete params.data['cognitoUserId'];
  delete params.data['notificationToken'];
  delete params.data['type'];
  delete params.data['createdAt'];
  delete params.data['updatedAt'];
  delete params.data['id'];

  delete params.data['isClone'];
  if (
    params.data &&
    'info' in params.data &&
    typeof params.data.info !== 'string' &&
    'images' in params.data.info
  ) {
    // Freshly dropped pictures are File objects and must be converted to base64 strings
    const newPictures = params.data.info.images.filter((p: any) => p.rawFile instanceof File);
    const formerPictures = params.data.info.images.filter((p: any) => !(p.rawFile instanceof File));
    return Promise.all(newPictures.map(convertFileToBase64))
      .then(base64Pictures =>
        base64Pictures.map(picture64 => ({
          src: picture64,
          title: `${params.data.title}`,
        })),
      )
      .then(transformedNewPictures => ({
        ...params.data,
        info: {
          ...params.data.info,
          images: [...transformedNewPictures, ...formerPictures],
        },
      }));
  }
  return params.data;
};
/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });
export default dataProvider;
