import React from 'react';
import {
    Create,
    Datagrid,
    DateField,
    DateInput,
    Edit,
    EditButton,
    List,
    NumberField,
    regex,
    required,
    SelectInput,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
} from 'react-admin';
import {BackAction} from "./backButton";
import {CustomToolbar} from './customToolbar';
//import {Link} from "./Link";
import {useRecordContext} from "ra-core";

const userRoles = [
    {id: 'user', name: 'User'},
    {id: 'company', name: 'Company'},
    {id: 'super_admin', name: 'Super Admin'},
];
const userFilters = [
    <TextInput source="search" label="Search"/>,
    // <ReferenceInput source="producerId" label="Company" reference="admin/company" allowEmpty>
    //     <SelectInput optionText="name"/>
    // </ReferenceInput>,
    <SelectInput source="group" choices={userRoles}/>
];


const validateAccountRepresentative = [required()];
const validatePhoneNumber = regex(/^\+\d{10}$/, 'Phone number should start from + and has 10 digits');
const validatePasswordNumber = [required(), regex(/^(?=.*?[A-Z])+(?=.*?[a-z])(?=.*?[0-9]).{6,}$/, 'Password should contains: at least one upper case, at lease one lowercase, at least one digit and minimum eight in length 6')]

// const CustomLinkProduct = ({source, record, label,}: any) => {
//     return <Link
//         prefix="#/admin/warranty/"
//         to={{
//             pathname: source,
//             search: `filter=${JSON.stringify({ownerId: record.id})}`,
//         }}>{label}</Link>
// }
// const CustomLinkProductWarranty = ({source, record, label,}: any) => {

//     // "E3AB31ED0CB4A1AFB670E6F3D98EBC24C9B394C17860C345EEEBF4CA3A043CE1
//     return <Link

//         to={{
//             pathname: 'product',
//             search: `filter=${JSON.stringify({ownerId: record.id})}`,
//         }}>{label}</Link>
// }


export const UserList = (props: any) => {
    return (
        <List  {...props} filters={userFilters} sort={{field: 'createdAt', order: 'DESC'}}>
            <Datagrid>
                {/*<TextField source="id" label="Id"/>*/}
                <TextField source="info.fullName" label="Full Name"/>
                <TextField source="email" label="Email"/>
                <TextField source="role" label="Business Role" emptyText="-"/>
                <TextField source="group" label="User Role" emptyText="-"/>
                <TextField source="kyc_status" label="KYC Status" emptyText="pending"/>
                <DateField source="createdAt"/>

                <ShowButton/>
                <EditButton/>
            </Datagrid>
        </List>
    );
};

export const UserShow = (props: any) => {

    const DataWithRecord = (props: any) => {
        const record = useRecordContext(props);
        return <>
            <a href={`/#/admin/product/?filter={"ownerId":"${record?.id}"}`}>Products</a>
            <a href={`/#/admin/product/?filter={"ownerId":"${record?.id}"}`}>Products With Warranty</a>
        </>
    }
    return (
        <Show {...props} actions={<BackAction/>}>
            <SimpleShowLayout>
                <TextField source="id" label="Id"/>
                <TextField source="info.fullName" label="Full name"/>
                <NumberField source="email" label="Email"/>
                <TextField source="info.phone" label="Phone" emptyText="-"/>
                <TextField source="info.address" label="Address" emptyText="-"/>
                <TextField source="info.birthdate" label="Birthdate" emptyText="-"/>
                <TextField source="group" label="Group"/>
                <TextField source="type" label="Type"/>
                <TextField source="walletAddress" label="Wallet address"/>
                <TextField source="publicKey" label="Wallet public key"/>
                <TextField source="kyc_status" label="KYC Status" emptyText="pending"/>
                <TextField source="kyc_reason" label="KYC Declined reason" emptyText="-"/>
                <TextField source="notificationToken" label="Notification Token" emptyText="-"/>
                <DateField source="createdAt"/>
                <DateField source="updatedAt"/>
                <DataWithRecord {...props}/>
            </SimpleShowLayout>
        </Show>
    );
}

export const UserEdit = (props: any) => (
    <Edit {...props} actions={<BackAction/>}>
        <SimpleForm toolbar={<CustomToolbar/>}>
            <TextInput source="id" disabled/>
            <TextInput source="email" label="Email" validate={validateAccountRepresentative}/>
            <SelectInput source="group" label="Group" choices={userRoles}/>
            <TextInput source="info.fullName" label="Name" validate={validateAccountRepresentative}/>
            <TextInput source="info.address" label="Address"/>
            <DateInput source="info.birthdate" label="Birthdate"/>
            <TextInput source="info.phone" label="Phone" validate={validatePhoneNumber}/>
        </SimpleForm>
    </Edit>
);

export const UserCreate = (props: any) => (
    <Create {...props} actions={<BackAction/>}>
        <SimpleForm>
            <TextInput source="email" label="Email" validate={validateAccountRepresentative}/>
            <TextInput source="password" label="Password" validate={validatePasswordNumber}/>
            <SelectInput source="group" label="Group" choices={userRoles}/>
            <TextInput source="info.fullName" label="Name" validate={validateAccountRepresentative}/>
            <TextInput source="info.address" label="Address"/>
            <DateInput source="info.birthdate" label="Birthdate"/>
            <TextInput source="info.phone" label="Phone" validate={validatePhoneNumber}/>
        </SimpleForm>
    </Create>
);
