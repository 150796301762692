import React from 'react';

export const Link = ({ source, prefix, postfix, name, record ,customTitle,target}: any) => {

    function resolveNestedHref(path: any, obj: any) {
        path = path.replace(/\[(\w+)\]/g, '.$1');
        path = path.replace(/^\./, '');
        let a = path?.split('.');
        for (let i = 0, n = a.length; i < n; ++i) {
            let k = a[i];
            if (k in obj) {
                obj = obj[k];
            } else {
                return;
            }
        }
        return obj;
    }

  function resolveHref(path: any, obj: any) {
      const properties = Array.isArray(path) ? path : path?.split('.');
      let found = properties?.reduce(function (prev: any, curr: any) {
            return prev ? prev[curr] : null;
        }, obj);
      if(!found) found = resolveNestedHref(path, obj);
      const url = `${prefix}${found}${postfix || ''}`;
      console.log('url :::', url);
      return url;
  }

  function resolveTitle(path: any, obj: any) {
    return path?.split('.')?.reduce(function (prev: any, curr: any) {
        return prev ? prev[curr] : null;
    }, obj);
  }

  return (
      <>
          {console.log(record)}
        <a href={resolveHref(source, record)} rel="noreferrer" target={target}>
            {customTitle || resolveTitle(name, record)}
        </a>
      </>
  );
};


