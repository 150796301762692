import * as React from "react";
import {DeleteButton, ListButton, SaveButton, Toolbar} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});


export const CustomToolbar = (props:any) => (
    //@ts-ignore
    <Toolbar {...props} classes={useStyles()}>
        <SaveButton />
        <ListButton basePath={props.basePath} label="Cancel" icon={<></>}   style={{marginRight:'auto',marginLeft:20}}/>
        <DeleteButton  />
    </Toolbar>
);
