import {Auth} from '@aws-amplify/auth';
import {Amplify} from '@aws-amplify/core';
import React from 'react';
import {Admin, Resource} from 'react-admin';
import {buildAuthProvider} from 'react-admin-amplify';
import {
    API_URL,
    AWS_COGNITO_REGION,
    AWS_COGNITO_USERPOOL_ID,
    AWS_COGNITO_USERPOOL_WEB_CLIENT_ID,
} from 'config/constants';
import dataProvider from './dataProvider';
import {UserCreate, UserEdit, UserList, UserShow} from './components/user';
import {CompanyCreate, CompanyEdit, CompanyList, CompanyShow} from './components/company';
import {ProductList, ProductShow} from './components/product';
import {WarrantyCreate, WarrantyEdit, WarrantyList, WarrantyShow} from './components/warranty';
import {ChangelogCreate, ChangelogEdit, ChangelogList, ChangelogShow} from './components/changelog';
import {WarrantyRequestList, WarrantyRequestShow} from './components/warranty-request';
import {ProofOfOwnershipList} from './components/proof-of-ownership';
import {OwnershipTransferList} from './components/ownership-transfer';
import UserIcon from '@material-ui/icons/People';
import BusinessIcon from '@material-ui/icons/Business';
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import {GppMaybe, SafetyCheck, ChangeHistory} from "@mui/icons-material";
import {Flag, Send} from "@material-ui/icons";

const authorizationHeader = async (): Promise<{ Authorization: string }> => {
    try {
        const session = await Auth.currentSession();
        const jwt = session.getAccessToken().getJwtToken()
        if (jwt) {
            return {Authorization: jwt};
        }
    } catch (e) {
        console.error('Amplify.configure: authorization header: ', e);
    }
    return {Authorization: 'None'};
};
Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: AWS_COGNITO_REGION,
        userPoolId: AWS_COGNITO_USERPOOL_ID,
        userPoolWebClientId: AWS_COGNITO_USERPOOL_WEB_CLIENT_ID,
        oauth: {
            scope: ["aws.cognito.signin.user.admin"],
        }
    },

    API: {
        endpoints: [
            {
                authorizationType: 'NONE',
                name: 'MyAPIGatewayAPI',
                endpoint: API_URL,
                custom_header: authorizationHeader,
            },
        ],
    },
    Analytics: {
        disabled: true,
    },
});


const App: React.FC = () => {
    return (
        <>
            {['dev','stage','preprod'] && <div className={`environment env_stage--${process.env.REACT_APP_TYPE}`}>{process.env.REACT_APP_TYPE?.toUpperCase()}</div>}
            <Admin
                authProvider={buildAuthProvider({authGroups: ['company_admin', 'super_admin']})}
                dataProvider={dataProvider()}>

                {
                    (group) => [

                        ['super_admin'].includes(group[0])
                            ? <Resource
                                name="admin/user"
                                options={{label: 'Users'}}
                                list={UserList}
                                create={UserCreate}
                                edit={UserEdit}
                                show={UserShow}
                                icon={UserIcon}
                            />
                            : null,

                        ['super_admin', 'company_admin'].includes(group[0])
                            ? <Resource
                                name="admin/company"
                                options={{label: 'Оrganizations'}}
                                list={CompanyList}
                                create={CompanyCreate}
                                edit={CompanyEdit}
                                show={CompanyShow}
                                icon={BusinessIcon}
                            />
                            : null,

                        // ['super_admin', 'company_admin'].includes(group[0])
                        //     ? <Resource
                        //         name="admin/employer"
                        //         options={{ label: 'Employers' }}
                        //         list={ EmployerList  }
                        //         create={ EmployerCreate }
                        //         edit={  EmployerEdit }
                        //         show={  EmployerShow }
                        //     />
                        //     : null,

                        ['super_admin'].includes(group[0])
                            ? <Resource
                                name="admin/product"
                                options={{label: 'Products'}}
                                list={ProductList}
                                // create={ProductCreate}
                                // edit={ProductEdit}
                                show={ProductShow}
                                icon={ShoppingCart}
                            />
                            : null,

                        ['super_admin'].includes(group[0])
                            ? <Resource
                                name="admin/warranty"
                                options={{label: 'Warranties'}}
                                list={WarrantyList}
                                create={WarrantyCreate}
                                edit={WarrantyEdit}
                                show={WarrantyShow}
                                icon={SafetyCheck}
                            />
                            : null,

                        ['super_admin'].includes(group[0])
                            ? <Resource
                                name="admin/product-history?filter[type]=registered-ownership"
                                options={{label: 'Proofs Of Ownership'}}
                                list={ProofOfOwnershipList}
                                icon={Flag}
                            />
                            : null,

                        ['super_admin'].includes(group[0])
                            ? <Resource
                                name="admin/product-history?filter[type]=transferred-ownership"
                                options={{label: 'Ownership Transfers'}}
                                list={OwnershipTransferList}
                                icon={Send}
                            />
                            : null,


                        ['super_admin'].includes(group[0])
                            ? <Resource
                                name="admin/warranty-requests"
                                options={{label: 'Warranty Requests'}}
                                list={WarrantyRequestList}
                                show={WarrantyRequestShow}
                                icon={GppMaybe}

                            />
                            : null,


                        ['super_admin'].includes(group[0])
                            ? <Resource
                                name="admin/changelog"
                                options={{label: 'Changelog'}}
                                list={ChangelogList}
                                create={ChangelogCreate}
                                edit={ChangelogEdit}
                                show={ChangelogShow}
                                icon={ChangeHistory}
                            />
                            : null,
                    ]
                }
            </Admin>
        </>
    );
};
export default App;
