import React from 'react';
import {
  Datagrid,
  DateField,
  List,
  TextField,
} from 'react-admin';
import { ImageLink } from "./imageLink";
import { Link } from "./Link";

export const ProofOfOwnershipList = (props: any) => {
  return (
    <List {...props} sort={{ field: 'createdAt', order: 'DESC' }}>
      <Datagrid>
        <Link prefix="#/admin/user/" postfix="/show" source="product.ownerId" name="product.ownerId" label="Current owner" />
        <Link prefix="#/admin/product/" postfix="/show"  source="productId" name="product.name" label="Product" />
        <TextField source="product.description" label="Description" />
        <ImageLink source="product.publicQrCode.Location" label="Product QrCode" />
        <TextField source="product.status" label="Status" />
        <DateField source="createdAt" label="Claimed" />
        {/*<ShowButton />*/}
      </Datagrid>
    </List>
  );
};
