import React from 'react';

export const ImageLink = ({ source, record, label, showLabel, size }: any) => {
  function resolve(path: any, obj: any) {
    return path.split('.').reduce(function (prev: any, curr: any) {
      return prev ? prev[curr] : null;
    }, obj);
  }
  const path = resolve(source, record);
  if (Array.isArray(path)) {
    return (
      <>
        <label className="MuiFormLabel-root MuiInputLabel-root RaLabeled-label-60 MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense">
          <span>{label}</span>
        </label>
        <div className="image-container">
          {path.map(item => {
            return (
              <a href={item.location} target="_blank" rel="noreferrer">
                <div className={`medium-image ${size}_image`}>
                  <img src={item.location} alt="item.location" />
                </div>
              </a>
            );
          })}
        </div>
      </>
    );
  }
  return (
    <>
      {showLabel && <p className="label">{label}</p>}
      <a href={resolve(source, record)} target="_blank" rel="noreferrer">
        <div className={`medium-image ${size ? `${size}_image` : ''}`}>
          <img src={resolve(source, record)} alt={resolve(source, record)} />
        </div>
      </a>
    </>
  );
};


